import React from 'react';
import { Helmet } from 'react-helmet';

const BASIN_ID = "babc502384b3"
const BASIN_FORM_ENDPOINT = `https://usebasin.com/f/${BASIN_ID}`;

export const EmailInputForm = ({ }) => {
  return (
    <>
      <div className='lg:pr-10'>
        <form action={BASIN_FORM_ENDPOINT} method="POST" id="my-contact-form" className="my-contact-form">
          <input type="text" id="email" name="email" placeholder="Podaj swój email" required class="emailInput" />
          <button type="submit" class="submitButton">ZAPISZ SIĘ NA LISTĘ OCZEKUJĄCYCH</button>
          <div className="form-message"></div>
        </form>
      </div>

      <Helmet>
        <script type="text/javascript">
          {`
            function configureAhoy() {
              ahoy.configure({
                visitsUrl: "https://usebasin.com/ahoy/visits",
                eventsUrl: "https://usebasin.com/ahoy/events",
                page: "${BASIN_ID}" /* Use your form id here */
              });
              ahoy.trackView();
              ahoy.trackSubmits();
            }
        `}
        </script>
        <script type="text/javascript">
          {`
            function getOnSubmitHandler (form, formMessage) {
              return function (event) {
                event.preventDefault();
                var formData = new FormData(form);
                var xhr = new XMLHttpRequest();
                xhr.open("POST", form.action, true);
                xhr.onload = function (e) {
                if (xhr.status === 200) {
                  formMessage.innerHTML = "Zapisano. Dziękuję, dam Tobie znać o postępach!";
                } else {
                  formMessage.innerHTML = "Błąd! Spróbuj ponownie za kilka minut!";
                }
                };
                xhr.send(formData);
              }
            }

            var forms = document.getElementsByClassName("my-contact-form");
            var messageElements = document.getElementsByClassName("form-message");

            forms[0].onsubmit = getOnSubmitHandler(forms[0], messageElements[0]);
            forms[1].onsubmit = getOnSubmitHandler(forms[1], messageElements[1]);
          `}
        </script>

        <script src="https://cdn.jsdelivr.net/npm/ahoy.js@0.3.9/dist/ahoy.min.js" async defer onload="configureAhoy()"></script>
      </Helmet>
    </>
  )
}
