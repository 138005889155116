import React from 'react';
import Layout from '../components/layout/Layout';
import { DriverHeroSection } from '../driver_sections/HeroSection';
import { DriverValuesSection } from '../driver_sections/ValuesSection';
import { CheckItSection } from '../driver_sections/CheckItSection';
import { DriverCentralCTASection } from '../driver_sections/CentralCTASection';
import { Helmet } from 'react-helmet';

const meta = {
  title: 'AutoSnap - Otrzymuj zdjęcia z naprawy, śledź historię Twojego pojazdu',
  desc: 'Otrzymuj zdjęcia z naprawy, śledź historię Twojego pojazdu. AutoSnap to elektroniczna książka serwisowa Twoich samochodów. Spraw, by kupiec Twojego samochodu docenił Twój wkład w jego utrzymanie.',
  keywords: 'aplikacja, warsztaty samochodowe, serwis, dokumentacja, historia',

}

const mouseFlowConfig = `
  window._mfq = window._mfq || [];
  (function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/7e873f8c-ca22-475b-b3da-dbb446035ae4.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();
`;

const HelmetSetup = () => {
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <Helmet
      title={meta.title}
      desc={meta.desc}
      keywords={meta.keywords}
    >
      <script>
        {isProduction && mouseFlowConfig}
      </script>
    </Helmet>
  )
}

const Index = () => (
  <Layout>
    <HelmetSetup/>
    <DriverHeroSection/>
    <DriverValuesSection/>
    <CheckItSection />
    <DriverCentralCTASection/>
    {/* {'WYKRESY: https://www.canva.com/design/play?template=EADzGRCrQZc&category=tADWs7A4Dr8' } */}
  </Layout>
);

export default Index;
