import React from 'react';
import howItWorksImgSrc from './how-it-works-img.png';
const DoubleArrowSvg = () => {
  return (
    <span>
      <svg role="img" style={{ display: 'inline'}} xmlns="http://www.w3.org/2000/svg" width="37px" height="37px" viewBox="0 0 24 24" aria-labelledby="chevronsRightIconTitle" stroke="#0166A2" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" color="#0166A2"> <title id="chevronsRightIconTitle">Chevrons Right</title> <polyline points="13 7 18 12 13 17 13 17" /> <polyline points="7 7 12 12 7 17 7 17" /> </svg>
    </span>
  )
}

const StepListItem = ({ children }) => {
  return (
    <li className='flex mb-3'>
      <DoubleArrowSvg /><span style={{ textAlign: 'left' }}>{children}</span>
    </li>
  )
};

export const CheckItSection = ({}) => {
  return (
      <section className="pt-20 pb-20 md:pt-20 howItWorksSection">
        <div className="container mx-auto px-8 lg:flex justify-center">
          <h3 className="text-3xl font-semibold leading-tight md:text-center">
            Zobacz przykład
          </h3>
        </div>
        <div className="container mx-auto">
          <div className="md:pl-20 md:pr-20 px-8 px-8 text-xl font-light leading-relaxed pt-8 text-center">
            <p>
              Wpisz VIN (5TDBKRFH7FS157051) na stronie: <a style={{ textDecoration: 'underline' }} href="https://app.autosnap.pl">app.autosnap.pl</a><br />
              lub przejdź bezpośrednio do raportu: <a style={{ textDecoration: 'underline' }} href="https://app.autosnap.pl/raport/5TDBKRFH7FS157051">raport samochodu</a>
            </p>
          </div>
        </div>
      </section>
  )
}
