import SplitSection from '../components/SplitSection';
import KeyFeature from '../components/KeyFeature';

const B = ({children}) => (<b className="font-bolder">{children}</b>)

const LeftColumn = () => {
  return (
    <div className="lg:pr-10">
      <h3 className="text-3xl font-semibold leading-tight">Jak to wykorzystać?</h3>
      <p className="mt-8 text-xl font-light leading-relaxed">
        <ul>
          <KeyFeature>
            Wiesz, ze możesz warsztatowi zaufać, gdy nie ma problemu z dokumentowaniem swojej pracy.
          </KeyFeature>
          <KeyFeature>
            Kiedy nie masz możliwości być przy naprawie samochodu swojego lub bliskiej osoby, możesz poprosić o zdjęcia z naprawy. Aplikacja upraszcza udostępnianie zdjęć przez mechaników.
          </KeyFeature>
          <KeyFeature>
            Widzisz historyczne naprawy wraz ze zdjęciami. Łatwiej podejmiesz decyzję, kiedy dany element jest do wymienienia.
          </KeyFeature>
          <KeyFeature>
            W przypadku stłuczki lub wypadku masz dowód wykonania serwisu. Dzięki temu dostaniesz wyższe odszkodowanie.
          </KeyFeature>
          <KeyFeature>
            Całą dokumentację możesz odsprzedać razem<br /> z samochodem. Stajesz się wiarygodnym sprzedawcą <br/> i możesz liczyć na większy zysk.
          </KeyFeature>
        </ul>
      </p>
    </div>
  )
}


const RightColumn = () => {
  return (
    <div className="lg:pl-10">
      <h3 className="text-3xl font-semibold leading-tight">Jak to działa?</h3>
      <p className="mt-8 text-xl font-light leading-relaxed">
        <ul>
          <KeyFeature orderNumber={1}>
            Odwiedź mechanika, który używa AutoSnap lub wyślij swojemu zaproszenie z aplikacji. Będzie on mógł natychmiastowo przesłać Tobie zdjęcia i opis.
          </KeyFeature>
          <KeyFeature orderNumber={2}>
            Zdjęcia z wizyty są przechowywane w profilu Twojego pojazdu.
          </KeyFeature>
          <KeyFeature orderNumber={3}>
            Historia napraw wraz ze zdjęciami jest dostępna pod numerem VIN. Dzięki temu, przy sprzedaży, możesz przedstawić stan samochodu swojemu kupcowi.
          </KeyFeature>
          <KeyFeature orderNumber={4}>
            Dodatkowo możesz tworzyć własne wpisy z tankowaniem, własnymi naprawami, wizualizować je i ustawiać przypomnienia o przeglądach.
          </KeyFeature>
        </ul>
      </p>
    </div>
  )
}

export const DriverValuesSection = ({ }) => {
  return (
    <SplitSection
      id="services"
      primarySlot={<LeftColumn/>}
      secondarySlot={<RightColumn />}
    />
  )
}
