import React from 'react';
import { EmailInputForm } from '../components/EmailInputForm';
import viewsImageSrc from './hero_image_drivers.png';
import KeyFeature from '../components/KeyFeature';

const Wave = () => {
  return (
    <div>
      <svg id="wave" viewBox="0 0 1440 100" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stop-color="rgba(0, 0, 0, 1)" offset="0%"></stop><stop stop-color="rgba(0, 0, 0, 1)" offset="100%"></stop></linearGradient></defs><path fill="white" d="M0,0L80,15C160,30,320,60,480,65C640,70,800,50,960,36.7C1120,23,1280,17,1440,26.7C1600,37,1760,63,1920,63.3C2080,63,2240,37,2400,36.7C2560,37,2720,63,2880,75C3040,87,3200,83,3360,71.7C3520,60,3680,40,3840,35C4000,30,4160,40,4320,45C4480,50,4640,50,4800,56.7C4960,63,5120,77,5280,80C5440,83,5600,77,5760,68.3C5920,60,6080,50,6240,46.7C6400,43,6560,47,6720,50C6880,53,7040,57,7200,48.3C7360,40,7520,20,7680,11.7C7840,3,8000,7,8160,20C8320,33,8480,57,8640,65C8800,73,8960,67,9120,60C9280,53,9440,47,9600,43.3C9760,40,9920,40,10080,45C10240,50,10400,60,10560,58.3C10720,57,10880,43,11040,41.7C11200,40,11360,50,11440,55L11520,60L11520,100L11440,100C11360,100,11200,100,11040,100C10880,100,10720,100,10560,100C10400,100,10240,100,10080,100C9920,100,9760,100,9600,100C9440,100,9280,100,9120,100C8960,100,8800,100,8640,100C8480,100,8320,100,8160,100C8000,100,7840,100,7680,100C7520,100,7360,100,7200,100C7040,100,6880,100,6720,100C6560,100,6400,100,6240,100C6080,100,5920,100,5760,100C5600,100,5440,100,5280,100C5120,100,4960,100,4800,100C4640,100,4480,100,4320,100C4160,100,4000,100,3840,100C3680,100,3520,100,3360,100C3200,100,3040,100,2880,100C2720,100,2560,100,2400,100C2240,100,2080,100,1920,100C1760,100,1600,100,1440,100C1280,100,1120,100,960,100C800,100,640,100,480,100C320,100,160,100,80,100L0,100Z"></path></svg>
    </div>
  )
}

export const DriverHeroSection = ({}) => {
  return (
    <div className='heroBackground'>
      <section className="pt-20 md:pt-40 pb-10">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-5xl md:text-6xl font-bold leading-none mb-10 uvp">
              Otrzymuj zdjęcia z napraw Twoich pojazdów!
            </h1>
            <p className='text-xl'>
              AutoSnap to miejsce, gdzie mechanicy mogą tworzyć dla Ciebie dokumentację samochodu wraz ze zdjęciami przed/w trakcie/po naprawie.
            </p>
            <p className="text-xl lg:text-xl mt-6 mb-10 font-light">
              <ul>
                <KeyFeature>Masz wgląd w jakość przeprowadzonych usług.</KeyFeature>
                <KeyFeature>Możesz prowadzić dziennik pojazdu i śledzić jego historię.</KeyFeature>
                <KeyFeature>Dzięki temu drożej sprzedasz samochód.</KeyFeature>
              </ul>
            </p>
            <p className="mt-4 text-white-600 mb-2">
              <b className='font-bold'>Otrzymaj wczesny dostęp do aplikacji:</b>
            </p>
            <EmailInputForm/>
          </div>
          <div className="relative md:mt-12 lg:mt-0">
            <div className="lg:w-3/5 md:w-2/4 lg:float-right float-none mx-auto">
              <img src={viewsImageSrc} alt="widoki aplikacji dla warsztatów samochodowych"/>
            </div>
          </div>
        </div>
      </section>
      <Wave/>
    </div>
  )
}
