import { EmailInputForm } from '../components/EmailInputForm';

export const DriverCentralCTASection = ({}) => {
  return (
    <section className="pt-20 footerSection pb-40">
      <div className="container px-8 sm:w-8/12 xl:w-5/12 mx-auto">
        <div className="container mx-auto mb-10 lg:flex justify-center">
          <h3 className="text-3xl font-semibold leading-tight lg:text-center">
            Zapisz się
          </h3>
        </div>
        <div>
          <div className="mb-2 text-lg">
            Otrzymaj wczesny dostęp do aplikacji:
          </div>
          <EmailInputForm />
        </div>
      </div>
    </section>
  )
}
